import CssUtils, { AppColors } from "../modules/utils/CssUtils"

export default {
  appChannel: "Local",
  urlMatch: "",
  apiURL: "http://localhost:8080",
  umami: {
    url: "https://umami.pub.stg.hou.hermesgermany.digital/script.js",
    id: "a7a7b6d2-4d0f-4717-91fb-6ab3b28872d5",
  },
  keycloak: {
    authServerUrl: "https://keycloak-test.hermesgermany.digital/auth",
    realm: "production",
    clientId: "zsb-cockpit-staging",
  },
  linkedAppsURLs: {
    zsbCockpit: "https://zsb-cockpit-staging.hermesgermany.digital",
    deviceManagment: "https://mpt-devicemanagement-staging.hermesgermany.digital",
    lastMileAnalytics: "https://last-mile-analytics-staging.hc.de",
    lastMileAdm: "https://last-mile-administration-staging.hermesgermany.digital",
  },
  testRegistration: true,
  sidebarColor: CssUtils.getVariable(AppColors.SIDEBAR_LOCAL),
}
