import CssUtils, { AppColors } from "../modules/utils/CssUtils"

export default {
  appChannel: "production",
  urlMatch: "zsb-sorter-config.hermesgermany.digital",
  apiURL: "https://zsb-sorter-config-api.hermesgermany.digital",
  umami: {
    url: "https://umami.pub.prd.hou.hermesgermany.digital/script.js",
    id: "636acee3-679e-4189-abc6-2a90903dc9c0",
  },
  keycloak: {
    authServerUrl: "https://keycloak.hermesgermany.digital/auth",
    realm: "production",
    clientId: "zsb-cockpit-production",
  },
  linkedAppsURLs: {
    zsbCockpit: "https://zsb-cockpit.hermesgermany.digital",
    deviceManagment: "https://mpt-devicemanagement.hermesgermany.digital",
    lastMileAnalytics: "https://last-mile-analytics.hc.de",
    lastMileAdm: "https://last-mile-administration.hermesgermany.digital",
  },
  testRegistration: false,
  sidebarColor: CssUtils.getVariable(AppColors.SIDEBAR_PROD),
}
